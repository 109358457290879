import Img from 'components/common/Img'
import { MEDIA_URL } from 'config/constants'
import formatMoney from 'lib/formatMoney'
import { IProduct } from 'store/api'

const HeaderSearchItem = ({ item }: { item: IProduct }) => {
  const { price, name, image, short_desc } = item

  return (
    <div className='flex h-max w-full justify-between items-start bg-white'>
      <div className='w-20 h-20 relative flex-shrink-0 overflow-hidden rounded-lg'>
        <Img
          src={`${MEDIA_URL}${image}`}
          alt={name}
          layout='fill'
          objectFit='cover'
        />
      </div>

      <div className='ml-4 gap-y-1 w-full flex flex-col'>
        <div className='flex gap-y-1 flex-col'>
          <span className='font-bold text-base mb-2'>{name}</span>

          <span className='text-base text-dark font-semibold md:text-right bg-lightYellow rounded p-1 w-max'>
            {formatMoney(price)}
          </span>
          <div className='text-xs text-bodyGrey leading-tight'>
            {short_desc}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderSearchItem
