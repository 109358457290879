import { isPresent } from 'lib/utils'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IAlert, selectCities } from 'store/api'
import { selectCity } from 'store/auth'
import useSlug from './useSlug'

const useAlerts = (pageType: string) => {
  const { queryLng } = useSlug()

  const city = useSelector(selectCity)
  const cities = useSelector(selectCities(queryLng))

  const alerts: IAlert[] = useMemo(() => {
    const cityInfo = cities.find((c) => c.code === city)
    return isPresent(cityInfo) ? cityInfo.alerts : []
  }, [cities, city])

  if (pageType === 'home')
    return alerts.filter((a) => a.pages === null)
  else return alerts.filter((a) => a.pages?.includes(pageType))
}

export default useAlerts
