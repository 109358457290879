import { LOCALES } from 'config/constants'
import { isSame } from 'lib/utils'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { IAuthState, selectLng } from 'store/auth'

let useSlug = <T extends string | string[] | undefined>() => {
  let router = useRouter()
  let query = router.query as Record<string, string>
  let slug = router.query['slug'] as T
  let lng = useSelector(selectLng)

  return {
    router,
    query,
    slug,
    queryLng: (lng ?? LOCALES.includes(router.locale ?? '')
      ? router.locale
      : 'ru') as NonNullable<IAuthState['lng']>,
    showOnlyContent: isSame(query['content-only'], '1'),
  }
}

export default useSlug
