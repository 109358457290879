import ExtLink from 'components/common/ExtLink'
import TelegramIcon from 'components/icons/TelegramIcon'
import VkIcon from 'components/icons/VkIcon'
import WhatsAppIcon2 from 'components/icons/WhatsAppIcon2'
import FooterLogo from 'components/logos/FooterLogo'
import {
  ADDRESS,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  TELEGRAM_LINK,
  VK_LINK,
} from 'config/constants'
import { isAbsent, isNonEmptyArray } from 'lib/utils'
import Image from 'next/image'
import appStore from 'public/img/App_Store.png'
import googlePlay from 'public/img/Google_Play.png'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCities } from 'store/api'

const Footer = () => {
  const cities = useSelector(selectCities('ru'))
  const defaultCity = useMemo(
    () => (isNonEmptyArray(cities) ? cities[0] : null),
    [cities]
  )

  // const openHours = useMemo(
  //   () =>
  //     `Ежедневно ${defaultCity?.call_time.begin || '08:00'} - ${
  //       defaultCity?.call_time.end || '20:00'
  //     }`,
  //   [defaultCity]
  // )

  if (isAbsent(defaultCity)) return null

  return (
    <footer className='bg-lightGrey custom-px pt-6 md:pt-4 pb-10 bg-spoons-footer-pattern'>
      <div className='grid grid-cols-2 justify-between gap-4 md:flex pb-4 lg:pb-2 lg:pt-2'>
        <FooterLogo className='hidden md:block md:w-[125px] md:h-[80px] col-span-2 md:col-span-1' />
        <div className='lg:mt-8'>
          <div className='font-bold text-sm text-dark !leading-6'>
            Режим работы:
          </div>
          <div className='text-mediumDark text-xs md:text-base2 !leading-5'>
            пн-сб 8:00–20:00
            <br />
            вс 9:00–20:00
          </div>
        </div>
        <div className='lg:mt-8'>
          <div className='font-bold text-sm text-dark !leading-6'>
            Телефоны:
          </div>
          <a
            className='text-mediumDark text-xs md:text-base2 !leading-6'
            href={`tel:+${
              defaultCity?.phones[0]?.replace(/\D/g, '') ||
              CONTACT_PHONE.replace(/\D/g, '')
            }`}
          >
            {defaultCity?.phones[0] || CONTACT_PHONE}
          </a>
        </div>
        <div className='flex flex-col row-start-1 col-span-2 md:col-span-1 lg:mt-8'>
          <div className='text-sm !leading-6 font-bold text-dark inline-flex items-center gap-2'>
            Адрес:
          </div>
          <div className='text-xs md:text-base2 !leading-6 text-mediumDark'>
            {defaultCity?.location?.address || ADDRESS}
          </div>
        </div>
        <div className='row-start-3 col-start-2 lg:mt-8'>
          <div className='font-bold text-sm text-dark !leading-6'>
            E-mail:{' '}
          </div>
          <a
            className='text-mediumDark text-xs md:text-base2 !leading-6 underline'
            href={`mailto:${CONTACT_EMAIL}`}
          >
            {CONTACT_EMAIL}
          </a>
        </div>
        <div className='text-xs md:text-sm !leading-6 row-start-3 col-start-1 lg:mt-8'>
          <iframe
            title='Рейтинг организации в Яндекс'
            src='https://yandex.ru/sprav/widget/rating-badge/1286099425?type=rating'
            width='150'
            height='50'
            frameBorder='0'
          ></iframe>
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-col'>
        <div className='flex justify-between flex-col md:flex-row items-center pt-4'>
          <div className='flex gap-3 items-center'>
            <ExtLink title='ВКонтакте' href={VK_LINK}>
              <VkIcon className='w-10 h-10 rounded hover:text-darkYellow' />
            </ExtLink>
            <ExtLink title='Телеграм' href={TELEGRAM_LINK}>
              <TelegramIcon className='w-10 h-10 rounded hover:text-darkYellow' />
            </ExtLink>
            <ExtLink
              title='WhatsApp'
              href={`https://wa.me/+79804002225`}
              className='flex flex-col items-center gap-2.5'
            >
              <WhatsAppIcon2 className='w-10 h-10 rounded hover:text-darkYellow' />
            </ExtLink>
          </div>
          <div className='flex flex-col md:flex-row gap-1 md:gap-4 items-center md:pt-2'>
            <span className='pt-2 md:pt-0 block text-dark/70 text-xs md:text-base2'>
              Скачивайте приложение:
            </span>
            <div className='flex flex-row gap-1 md:gap-3'>
              <ExtLink href='https://play.google.com/store/apps/details?id=com.moscowfood'>
                <Image
                  src={googlePlay}
                  layout='fixed'
                  objectFit='contain'
                  width='120px'
                  height='40px'
                  title='Google Play'
                  alt='Google Play'
                />
              </ExtLink>
              <ExtLink href='https://apps.apple.com/ru/app/moscowfood/id6447418248'>
                <Image
                  src={appStore}
                  layout='fixed'
                  objectFit='contain'
                  width='120px'
                  height='40px'
                  title='App Store'
                  alt='App Store'
                />
              </ExtLink>
            </div>
          </div>
        </div>
        <div className='hidden md:block text-dark text-2xs md:text-xs md:text-center leading-tight text-justify pt-4'>
          {defaultCity.footer?.footerMsg || (
            <>
              Moscow Food - это более 15 лет успешной работы в сфере
              общественного питания. Профессиональная команда в сфере
              кейтеринговых и ивент услуг и разнообразное меню.
              Команда профессиональных поваров готова предложить Вам
              широкий ассортимент блюд на любой вкус — от классической
              европейской кухни до экзотических блюд.
            </>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
