import { Dialog, Transition } from '@headlessui/react'
import ExtLink from 'components/common/ExtLink'
import Button from 'components/forms/Button'
import ChatIcon from 'components/icons/ChatIcon'
import PhoneIcon from 'components/icons/PhoneIcon'
import TelegramIcon from 'components/icons/TelegramIcon'
import VkIcon from 'components/icons/VkIcon'
import WhatsAppIcon2 from 'components/icons/WhatsAppIcon2'
import {
  CONTACT_PHONE,
  TELEGRAM_LINK,
  VK_LINK,
} from 'config/constants'
import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIsMd, selectIsSm } from 'store/ui'

const HeaderContact = () => {
  const isSm = useSelector(selectIsSm)
  const isMd = useSelector(selectIsMd)

  const [isOpen, setIsOpen] = useState(false)

  if (!isSm && !isMd) return null

  return (
    <div className='block lg:hidden'>
      <Button
        onClick={() => setIsOpen(true)}
        className='-m-2 p-2.5 group bg-lightGrey rounded-full flex-shrink-0'
        title='Связаться с нами'
      >
        <ChatIcon className='h-6 w-6' aria-hidden='true' />
      </Button>

      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-50'
          onClose={() => setIsOpen(false)}
        >
          <div className='fixed inset-0 flex items-end justify-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-in duration-75'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-75'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-dark bg-opacity-75 transition-opacity' />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-500 transform'
              enterFrom='translate-y-full'
              enterTo='translate-y-0'
              leave='transition ease-in-out duration-500 transform'
              leaveFrom='translate-y-0'
              leaveTo='translate-y-full'
            >
              <div className='rounded-t-[40px] bg-white w-full h-[185px]'>
                <div
                  className='z-[100] bg-transparent absolute h-[80px] w-full'
                  onClick={() => setIsOpen(false)}
                >
                  <div className='h-1 w-10 mx-auto mt-2 bg-mediumDark rounded-md'></div>
                </div>
                <div className='pt-7 mb-12 max-w-[250px] mx-auto'>
                  <div className='text-center text-base font-unbounded font-medium text-dark mb-6'>
                    Связаться с нами:
                  </div>
                  <div className='flex gap-9 items-center justify-center'>
                    <ExtLink
                      href={VK_LINK}
                      className='flex flex-col items-center gap-2.5 focus:outline-none'
                    >
                      <VkIcon className='w-10 h-10 rounded' />
                      <span className='text-xs text-bodyGrey'>
                        ВКонтакте
                      </span>
                    </ExtLink>
                    <ExtLink
                      href={TELEGRAM_LINK}
                      className='flex flex-col items-center gap-2.5 focus:outline-none'
                    >
                      <TelegramIcon className='w-10 h-10 rounded' />
                      <span className='text-xs text-bodyGrey'>
                        Telegram
                      </span>
                    </ExtLink>
                    <ExtLink
                      href={`https://wa.me/+79804002225`}
                      className='flex flex-col items-center gap-2.5 focus:outline-none'
                    >
                      <WhatsAppIcon2 className='w-10 h-10 rounded' />
                      <span className='text-xs text-bodyGrey'>
                        WhatsApp
                      </span>
                    </ExtLink>
                    <ExtLink
                      href={`tel:+${CONTACT_PHONE.replace(
                        /\D/g,
                        ''
                      )}`}
                      className='flex flex-col items-center gap-2.5 focus:outline-none'
                    >
                      <PhoneIcon className='w-10 h-10 rounded' />
                      <span className='text-xs text-bodyGrey'>
                        Телефон
                      </span>
                    </ExtLink>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default HeaderContact
