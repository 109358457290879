import useSlug from 'hooks/useSlug'
import { isNonEmptyArray } from 'lib/utils'
import { useSelector } from 'react-redux'
import { selectBannersList } from 'store/api'
import PromoPanelBannerSlider from './PromoPanelBannerSlider'
import LoadingDots from './LoadingDots'

const PromoPanel = () => {
  let { queryLng } = useSlug()

  const banners = useSelector(
    selectBannersList(queryLng)
  )?.other_banners

  return (
    <div className='hidden lg:block relative bg-white min-w-[300px] max-w-[300px] shadow-md'>
      <div className='py-8 sticky top-[75px] h-[calc(100vh-85px)] flex flex-col'>
        <h3 className='px-6 text-center text-dark font-medium text-xl leading-6 font-unbounded mb-4'>
          Акции
        </h3>

        {isNonEmptyArray(banners) ? (
          <PromoPanelBannerSlider banners={banners} />
        ) : (
          <div className='my-5 h-5 mt-12 relative'>
            <LoadingDots className='h-3 w-3 mx-2 bg-accent' />
          </div>
        )}
      </div>
    </div>
  )
}

export default PromoPanel
