import { Transition } from '@headlessui/react'
import ResetButton from 'components/forms/ResetButton'
import SearchIcon from 'components/icons/SearchIcon'
import useClickOutside from 'hooks/useClickOutside'
import useSlug from 'hooks/useSlug'

import {
  ChangeEventHandler,
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { selectCategoryList, selectProductList } from 'store/api'
import HeaderSearchItem from './HeaderSearchItem'

const HeaderSearch = () => {
  const { queryLng, router } = useSlug()

  const [foundProducts, setFoundProducts] = useState<any[]>([])

  const _categoryList = useSelector(selectCategoryList(queryLng))

  const _products = useSelector(selectProductList(queryLng))

  const allProducts = useMemo(() => {
    const _temp: any = []
    _categoryList?.forEach((c) => {
      const products = _products[c.id.toString()]
      if (products !== undefined)
        _temp.push(
          products.map((p) => ({
            category: c.slug,
            ...p,
          }))
        )
    })
    return _temp.flat()
  }, [_categoryList, _products])

  const [ref, isOpen, setIsOpen] = useClickOutside(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const handleShowInput = useCallback(() => {
    setInputVisible(true)
  }, [])
  const handleHideInput = useCallback(() => {
    setSearchValue('')
    setInputVisible(false)
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const [inputVisible, setInputVisible] = useState(false)
  const onSearchValueChanged: ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setSearchValue(e.target.value.toLocaleLowerCase())
    }, [])

  const resetSearch = useCallback(() => {
    setSearchValue('')
    inputRef?.current?.focus()
  }, [])

  const onInputFocus: FocusEventHandler<HTMLInputElement> =
    useCallback((e) => {
      if (e.target.value.trim().length > 2) setIsOpen(true)
    }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchValue.trim().length > 2) {
        const filteredProducts = allProducts.filter((p: any) =>
          p.name.toLocaleLowerCase().includes(searchValue)
        )
        const withoutDupes = filteredProducts.length > 1 ? [
          ...filteredProducts
            .reduce((map: any, item: any) => {
              const key = item['id']
              map.has(key) || map.set(key, item)
              return map
            }, new Map())
            .values(),
        ] : filteredProducts
        setFoundProducts(withoutDupes)
        setIsOpen(true)
      }
    }, 1000)
    if (searchValue.trim() === '') {
      setFoundProducts([])
      setIsOpen(false)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [searchValue])

  useEffect(() => {
    if (inputVisible) {
      setSearchValue('')
      inputRef?.current?.focus()
    }
  }, [inputVisible])

  const navigateToProduct = useCallback(
    (product) => {
      const currentPath = router.pathname
      setSearchValue('')
      setIsOpen(false)
      setInputVisible(false)
      const newPath =
        currentPath.indexOf('categories') > -1
          ? product.category
          : `/categories/${product.category}`

      router.replace(
        {
          query: {
            product: product.id,
          },
          pathname: newPath,
        },
        newPath,
        { shallow: true }
      )
    },
    [router]
  )

  const noFound =
    searchValue.trim().length > 2 && foundProducts.length === 0

  return (
    <div className='relative'>
      <button
        type='button'
        onClick={handleShowInput}
        className='-m-2 mt-0 p-2.5 bg-lightGrey rounded-full lg:hidden'
        aria-label='Поиск'
      >
        <SearchIcon
          className='h-6 w-6 text-accent'
          aria-hidden='true'
        />
      </button>

      {inputVisible && (
        <div className='fixed lg:hidden ml-2 right-0 top-3.5 z-20 w-full'>
          <input
            id='search'
            ref={inputRef}
            aria-label='Поиск'
            className='form-input w-full py-4 pl-5 pr-10 tracking-tight'
            placeholder='Поисковый запрос'
            type='text'
            value={searchValue}
            onChange={onSearchValueChanged}
            onFocus={onInputFocus}
          />

          <ResetButton
            condition={true}
            onClick={handleHideInput}
            className='right-3 top-4.5'
          />
        </div>
      )}

      <div className='relative hidden lg:flex items-center gap-2 p-2 mx-2 border border-grey group hover:border-white focus-within:bg-white rounded-full bg-lightGrey overflow-hidden'>
        <button
          type='button'
          onClick={() => inputRef.current?.focus()}
          aria-label='Поиск'
        >
          <SearchIcon
            className='h-6 w-6 text-accent'
            aria-hidden='true'
          />
        </button>
        <input
          id='search'
          ref={inputRef}
          aria-label='Поиск'
          className='form-input focus:bg-white focus:placeholder-white max-w-56 tracking-tight text-base2 leading-none trabslate-y-0.5 text-mediumDark'
          placeholder='Поиск...'
          type='text'
          value={searchValue}
          onChange={onSearchValueChanged}
          onFocus={onInputFocus}
        />
        <ResetButton
          condition={searchValue.trim().length > 0}
          onClick={resetSearch}
          className='right-5 top-3'
        />
      </div>

      <Transition
        show={isOpen}
        as='div'
        enter='transition ease-out duration-200'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div
          ref={ref}
          className='right-0 top-16 md:top-12 h-[90%] md:h-auto w-full md:w-[500px] fixed md:absolute md:rounded border border-lightGrey bg-white shadow-md overflow-hidden'
        >
          <>
            {foundProducts.length > 0 && (
              <div className='relative p-5 overflow-y-auto thin-scrollbar h-full md:max-h-[20rem] xl:max-h-[28rem] space-y-3 divide-y divide-lightGrey'>
                {foundProducts.map((p, index) => (
                  <div
                    className='pt-2 -mt-2 cursor-pointer'
                    onClick={() => navigateToProduct(p)}
                    key={`${p.id}_${index}`}
                  >
                    <HeaderSearchItem item={p} />
                  </div>
                ))}
              </div>
            )}

            {noFound && (
              <div className='w-full p-4 flex justify-between'>
                <span className='font-bold text-accent'>
                  Ничего не найдено
                </span>
                <span
                  className='font-semibold cursor-pointer'
                  onClick={resetSearch}
                >
                  Сбросить ввод
                </span>
              </div>
            )}
          </>
        </div>
      </Transition>
    </div>
  )
}

export default HeaderSearch
