import clsx from 'clsx'
import ExtLink from 'components/common/ExtLink'
import DiscountIcon from 'components/icons/Discount'
import { isPresent, isSame } from 'lib/utils'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { IMenuItem } from 'store/api'

type Props = {
  menu?: IMenuItem[]
  leftMenu?: IMenuItem[]
}

let HeaderFlyoutMenu = ({ menu, leftMenu }: Props) => {
  const router = useRouter()

  let navigation = useMemo(
    () => ({
      categories: [
        {
          name: 'Меню',
          pages: leftMenu?.map((x) => ({
            ...x,
            href: `/categories`,
          })),
        },
      ],
      pages:
        menu?.map((x) => ({
          name: x.name,
          href: x.path,
        })) ?? [],
    }),
    [leftMenu, menu]
  )

  return (
    <nav className='h-12 text-base2 leading-5 hidden lg:flex items-center justify-evenly w-full custom-px border-b border-lightGrey'>
      {navigation.categories.map((category, categoryIdx) => (
        <div
          key={categoryIdx}
          className={clsx(
            'hover:text-accent tracking-tighter whitespace-nowrap pt-3 pb-3.5 flex-auto text-center',
            {
              'text-accent border-accent border-b-4':
                (isPresent(category.pages?.[0]?.href) &&
                  router.asPath.startsWith(
                    category.pages![0]!.href
                  )) ||
                router.asPath.startsWith('/product'),
            }
          )}
        >
          <Link href={category.pages?.[0]?.href ?? ''}>
            {category.name}
          </Link>
        </div>
      ))}

      {navigation.pages?.map(({ href, name }) => (
        <span
          key={href}
          className={clsx(
            'hover:text-accent tracking-tighter whitespace-nowrap pt-3 pb-3.5 flex-auto text-center',
            {
              'text-accent border-accent border-b-4':
                router.asPath.startsWith(href),
            }
          )}
        >
          {href.startsWith('http') ? (
            <ExtLink href={href}>{name}</ExtLink>
          ) : (
            <Link href={href}>
              <a className='inline-flex items-center'>
                {name}
                {isSame(href, '/aktsii') && (
                  <div className='ml-2'>
                    <DiscountIcon className='h-6 w-6' />
                  </div>
                )}
              </a>
            </Link>
          )}
        </span>
      ))}
    </nav>
  )
}

export default HeaderFlyoutMenu
