import clsx from 'clsx'

let PhoneIcon = ({ className }: { className?: string }) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={clsx(className)}
  >
    <rect width='40' height='40' rx='6' fill='currentColor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.7723 24.2214C19.7017 28.1528 24.6805 30.6585 27.6415 27.7099L28.3597 26.9917C29.3173 26.0359 29.1841 24.4518 28.0717 23.6796C27.3715 23.1918 26.6191 22.668 25.7875 22.0829C24.9271 21.4781 23.7481 21.5735 23.0011 22.3133L22.1893 23.1198C21.1831 22.4826 20.1877 21.6635 19.2625 20.7401L19.2589 20.7365C18.3355 19.8131 17.5165 18.8158 16.8793 17.8096L17.6857 16.9978C18.4273 16.2525 18.5191 15.0717 17.9161 14.2113C17.3293 13.3796 16.8055 12.6272 16.3195 11.927C15.5473 10.8163 13.9633 10.6831 13.0075 11.639L12.2893 12.3572C9.34266 15.3183 11.8465 20.2937 15.7759 24.2268'
      fill='white'
    />
  </svg>
)

export default PhoneIcon
