import clsx from 'clsx'
import IntLink from 'components/common/IntLink'
import BasketIcon from 'components/icons/BasketIcon'
import useCart from 'hooks/useCart'
import useSlug from 'hooks/useSlug'
import formatMoney from 'lib/formatMoney'
import { isDiff, isSame } from 'lib/utils'

import { useCallback } from 'react'

let HeaderCart = () => {
  let { router } = useSlug()
  let { cartQty, cartTotal } = useCart()

  let handleOpen = useCallback(() => {
    void router.push('/cart', undefined, { shallow: true })
  }, [router])

  return (
    <>
      <IntLink
        href='/cart'
        aria-label='Корзина'
        title='Корзина'
        className='lg:hidden -m-2 p-2.5 group bg-lightGrey rounded-full'
      >
        <BasketIcon
          className={clsx('h-6 w-6', {
            'text-darkYellow': isSame(router.asPath, '/cart'),
            'text-accent': isDiff(router.asPath, '/cart'),
          })}
          aria-hidden='true'
        />

        {cartQty > 0 && (
          <div className='shadow-md absolute -bottom-3 -right-3 w-[1.0625rem] h-[1.0625rem] text-accent bg-white rounded-full p-3 flex items-center justify-center font-bold text-sm tracking-tight'>
            {cartQty}
          </div>
        )}
      </IntLink>
      <div className='hidden lg:block relative'>
        <>
          <button
            type='button'
            onClick={handleOpen}
            className='-m-2 mt-0 p-2 group'
            aria-label='Корзина'
            title='Корзина'
          >
            <div className='flex gap-3 items-center'>
              <BasketIcon
                className={clsx('h-6 w-6 flex-shrink-0', {
                  'text-darkYellow': isSame(router.asPath, '/cart'),
                  'text-accent': isDiff(router.asPath, '/cart'),
                })}
                aria-hidden='true'
              />

              {cartQty > 0 ? (
                <span className='hover:text-accent text-base2 leading-none translate-y-0.5'>
                  {formatMoney(cartTotal)}
                </span>
              ) : (
                <span className='hover:text-accent text-base2 leading-none translate-y-0.5'>
                  Корзина пуста
                </span>
              )}
            </div>
          </button>
        </>
      </div>
    </>
  )
}

export default HeaderCart
