import { APP_ORIGIN } from 'config/constants'
import useCheckSiteStatus from 'hooks/useCheckSiteStatus'
import useSlug from 'hooks/useSlug'
import { isNonEmptyString } from 'lib/utils'
import Head from 'next/head'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { IMenuItem } from 'store/api'
import { selectIsXl } from 'store/ui'
import BreadcrumbMenu from './BreadcrumbMenu'
import Footer from './Footer'
import Header from './Header'
import PromoPanel from 'components/common/PromoPanel'

type Props = {
  title?: string
  description?: string
  keywords?: string
  canonical?: string
  children: ReactNode
  home?: boolean
  index?: boolean
  withCartPanel?: boolean
  menus?: Record<string, IMenuItem[]>
}

let Layout = ({
  title,
  description,
  keywords,
  // canonical,
  children,
  home = false,
  index = false,
  withCartPanel = false,
  menus = {},
}: Props) => {
  let { showOnlyContent, router } = useSlug()
  const { isAcceptingOrders, InfoBlock } = useCheckSiteStatus()

  const isXl = useSelector(selectIsXl)

  return (
    <>
      <Head>
        <title>{title}</title>
        {!index && <meta name='robots' content='noindex' />}
        {isNonEmptyString(description) && (
          <meta name='description' content={description} />
        )}
        {isNonEmptyString(keywords) && (
          <meta name='keywords' content={keywords} />
        )}

        {/* {isPresent(canonical) && (
          <link rel='canonical' href={canonical} />
        )} */}
        <link
          rel='canonical'
          href={`${APP_ORIGIN}${router.asPath}`}
        />

        {/* <link
          rel='alternate'
          href={`https://${process.env['NEXT_PUBLIC_APP_ORIGIN']}${router.asPath}`}
          hrefLang='ru'
        />

        <link
          rel='alternate'
          href={`https://${process.env['NEXT_PUBLIC_APP_ORIGIN']}/en${router.asPath}`}
          hrefLang='en'
        /> */}
      </Head>

      <div className='flex flex-col justify-between w-full min-h-screen+ max-w-full mx-auto'>
        {!showOnlyContent && <Header menusSSR={menus} />}
        <div className='flex-grow flex flex-col'>
          {!isAcceptingOrders && withCartPanel && (
            <InfoBlock className='bg-alert text-accent text-xs lg:text-base2 font-semibold text-center p-3' />
          )}

          <main className='flex-grow-0 max-w-[100vw] relative flex'>
            {withCartPanel ? (
              <>
                <div className='border-r border-lightGrey relative flex-grow w-full xl:max-w-[calc(100%-300px)]'>
                  {children}
                </div>

                {isXl && <PromoPanel />}
              </>
            ) : (
              <div className='relative flex-grow w-full'>
                {!home && <BreadcrumbMenu menusSSR={menus} />}
                {children}
              </div>
            )}
          </main>
        </div>
        {!showOnlyContent && <Footer />}
      </div>
    </>
  )
}

export default Layout
