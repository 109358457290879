import { isDiff } from 'lib/utils'
import { useEffect, useLayoutEffect } from 'react'

let useIsomorphicLayoutEffect =
  isDiff(typeof window, 'undefined') &&
  isDiff(typeof window.document, 'undefined') &&
  isDiff(typeof window.document.createElement, 'undefined')
    ? useLayoutEffect
    : useEffect

export default useIsomorphicLayoutEffect
