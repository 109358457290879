import {
  isNonEmptyArray,
  isNonEmptyString,
  isPresent,
} from 'lib/utils'
import { useMemo } from 'react'
import { useGetSiteInfoQuery } from 'store/api'
import useAlerts from './useAlerts'

const useCheckSiteStatus = () => {
  const { data: siteInfo, isSuccess } = useGetSiteInfoQuery('', {
    pollingInterval: 300000, // refetch per 5 minutes
  })
  const isAcceptingOrders: boolean = useMemo(() => {
    if (isSuccess && isPresent(siteInfo))
      return siteInfo.accepting_orders
    return true
  }, [isSuccess, siteInfo])

  const alerts = useAlerts('page')

  const notAcceptingOrdersTitle = isNonEmptyArray(alerts)
    ? alerts[0]?.title
    : ''
  const notAcceptingOrdersText = isNonEmptyArray(alerts)
    ? alerts[0]?.text
    : ''

  const InfoBlock = ({ className }: { className?: string }) => {
    return (
      <div className={className}>
        {isNonEmptyString(notAcceptingOrdersTitle) && (
          <div>{notAcceptingOrdersTitle}</div>
        )}
        {isNonEmptyString(notAcceptingOrdersText) && (
          <div>{notAcceptingOrdersText}</div>
        )}
      </div>
    )
  }

  return {
    isAcceptingOrders,
    InfoBlock,
  }
}
export default useCheckSiteStatus
