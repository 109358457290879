import clsx from 'clsx'
import LoadingCircle from 'components/common/LoadingCircle'
import LoadingDots from 'components/common/LoadingDots'
import useHackLink from 'hooks/useHackLink'
import { parseQueryString } from 'lib/misc'
import {
  addPropIf,
  isDiff,
  isPresent,
  isSame,
  isString,
  Obj,
} from 'lib/utils'
// import useHackLink from 'hooks/useHackLink'
// import { parseQueryString } from 'lib/misc'
// import { isPresent, isString, Obj } from 'lib/utils'
import {
  forwardRef,
  JSXElementConstructor,
  PropsWithoutRef,
  useCallback,
  useMemo,
} from 'react'
import { UrlObject } from 'url'

interface Props
  extends PropsWithoutRef<JSX.IntrinsicElements['button']> {
  className?: string
  type?: 'submit' | 'reset' | 'button'
  as?: 'button' | 'a' | JSXElementConstructor<any>
  loading?: boolean
  disabled?: boolean
  selected?: boolean
  loadingClasses?: string
  withLoadingCircle?: boolean
  href?: UrlObject | string
  replace?: boolean
  scroll?: boolean
  shallow?: boolean
}

let Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (props, outerRef) => {
    let handleClick = useHackLink()

    let {
      className,
      children,
      loading = false,
      disabled = false,
      selected = false,
      as: Component = 'button',
      type = 'button',
      loadingClasses,
      withLoadingCircle = false,
      href,
      replace,
      scroll,
      shallow,
      ...rest
    } = props

    let rootClassName = clsx(
      `btn`,
      {
        'btn-selected': selected,
        'btn-disabled': disabled || loading,
        'btn-loading': loading,
      },
      className
    )

    let LoadingComponent = useMemo(
      () => (withLoadingCircle ? LoadingCircle : LoadingDots),
      [withLoadingCircle]
    )

    let hrefAsString: string | undefined

    if (isPresent(href))
      hrefAsString = isString(href)
        ? href
        : `${href?.pathname}${parseQueryString(
            (href?.query as Obj) ?? {},
            {}
          )}`

    let _handleClick = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        isSame(props.as, 'a') &&
          isPresent(hrefAsString) &&
          handleClick(e, href ?? '', {
            replace,
            scroll,
            shallow,
          })
      },
      [
        handleClick,
        href,
        hrefAsString,
        props.as,
        replace,
        scroll,
        shallow,
      ]
    )

    return (
      <Component
        ref={outerRef}
        className={rootClassName}
        disabled={disabled || loading}
        {...addPropIf(isDiff(props.as, 'a'), 'type', type)}
        onClick={_handleClick}
        {...addPropIf(
          isSame(props.as, 'a') && isPresent(hrefAsString),
          'href',
          hrefAsString
        )}
        {...rest}
      >
        <span className={clsx({ invisible: loading })}>
          {children}
        </span>

        {loading && <LoadingComponent className={loadingClasses} />}
      </Component>
    )
  }
)

export default Button
