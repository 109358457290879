import clsx from 'clsx'

let BasketIcon = ({ className }: { className?: string }) => (
  <svg
    className={clsx(className)}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.49854 6.99825V6.49804C8.49854 4.56424 10.0662 2.99658 12 2.99658V2.99658C13.9338 2.99658 15.5015 4.56424 15.5015 6.49804V6.99825'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.99917 10.9995V11.9999'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.0008 10.9995V11.9999'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.5015 15.6895C13.4952 17.4395 10.5048 17.4395 8.49854 15.6895'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.46174 6.99805C5.40519 6.99805 4.53076 7.81958 4.46485 8.87407L4.00566 16.2212C3.92814 17.4615 4.36678 18.6787 5.21766 19.5844C6.06855 20.4901 7.25599 21.0039 8.49871 21.0039H15.5013C16.744 21.0039 17.9315 20.4902 18.7824 19.5844C19.6333 18.6787 20.0719 17.4615 19.9944 16.2212L19.5352 8.87407C19.4693 7.81958 18.5948 6.99805 17.5383 6.99805H6.46174Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default BasketIcon
