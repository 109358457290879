import clsx from 'clsx'

let DiscountIcon = ({ className }: { className?: string }) => (
  <svg
    className={clsx(className)}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.3776 5.74646L20.3029 5.94863C21.15 6.13369 21.6549 7.00762 21.3918 7.83448L21.104 8.73729C20.9297 9.28604 21.0901 9.88506 21.5159 10.2734L22.2165 10.9109C22.8573 11.4949 22.8573 12.5047 22.2165 13.0888L21.5159 13.7263C21.0901 14.1135 20.9297 14.7136 21.104 15.2624L21.3918 16.1652C21.6549 16.992 21.15 17.866 20.3029 18.051L19.3776 18.2532C18.8149 18.3762 18.3764 18.8148 18.2533 19.3774L18.0512 20.3027C17.8661 21.1499 16.9922 21.6548 16.1653 21.3916L15.2625 21.1039C14.7138 20.9295 14.1147 21.09 13.7264 21.5157L13.0889 22.2164C12.5049 22.8571 11.4951 22.8571 10.911 22.2164L10.2735 21.5157C9.88628 21.09 9.28619 20.9295 8.73745 21.1039L7.83463 21.3916C7.00777 21.6548 6.13384 21.1499 5.94878 20.3027L5.74661 19.3774C5.6236 18.8148 5.18503 18.3762 4.62238 18.2532L3.6971 18.051C2.84991 17.866 2.34502 16.992 2.60817 16.1652L2.89591 15.2624C3.07027 14.7136 2.90982 14.1146 2.48408 13.7263L1.78451 13.0888C1.14377 12.5047 1.14377 11.4949 1.78451 10.9109L2.48515 10.2734C2.91089 9.88613 3.07134 9.28604 2.89698 8.73729L2.60817 7.83448C2.34502 7.00762 2.84991 6.13369 3.6971 5.94863L4.62238 5.74646C5.18503 5.62345 5.6236 5.18488 5.74661 4.62223L5.94878 3.69695C6.13384 2.84976 7.00777 2.34487 7.83463 2.60801L8.73745 2.89576C9.28619 3.07012 9.88521 2.90966 10.2735 2.48393L10.911 1.78329C11.4951 1.14255 12.5049 1.14255 13.0889 1.78329L13.7264 2.48393C14.1137 2.90966 14.7138 3.07012 15.2625 2.89576L16.1653 2.60801C16.9922 2.34487 17.8661 2.84976 18.0512 3.69695L18.2533 4.62223C18.3764 5.18488 18.8149 5.62345 19.3776 5.74646Z'
      fill='#FCB20A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.897 6.15376L19.762 6.34276C20.554 6.51576 21.026 7.33276 20.78 8.10576L20.511 8.94976C20.348 9.46276 20.498 10.0228 20.896 10.3858L21.551 10.9818C22.15 11.5278 22.15 12.4718 21.551 13.0178L20.896 13.6138C20.498 13.9758 20.348 14.5368 20.511 15.0498L20.78 15.8938C21.026 16.6668 20.554 17.4838 19.762 17.6568L18.897 17.8458C18.371 17.9608 17.961 18.3708 17.846 18.8968L17.657 19.7618C17.484 20.5538 16.667 21.0258 15.894 20.7798L15.05 20.5108C14.537 20.3478 13.977 20.4978 13.614 20.8958L13.018 21.5508C12.472 22.1498 11.528 22.1498 10.982 21.5508L10.386 20.8958C10.024 20.4978 9.46298 20.3478 8.94998 20.5108L8.10598 20.7798C7.33298 21.0258 6.51598 20.5538 6.34298 19.7618L6.15398 18.8968C6.03898 18.3708 5.62898 17.9608 5.10298 17.8458L4.23798 17.6568C3.44598 17.4838 2.97398 16.6668 3.21998 15.8938L3.48898 15.0498C3.65198 14.5368 3.50198 13.9768 3.10398 13.6138L2.44998 13.0178C1.85098 12.4718 1.85098 11.5278 2.44998 10.9818L3.10498 10.3858C3.50298 10.0238 3.65298 9.46276 3.48998 8.94976L3.21998 8.10576C2.97398 7.33276 3.44598 6.51576 4.23798 6.34276L5.10298 6.15376C5.62898 6.03876 6.03898 5.62876 6.15398 5.10276L6.34298 4.23776C6.51598 3.44576 7.33298 2.97376 8.10598 3.21976L8.94998 3.48876C9.46298 3.65176 10.023 3.50176 10.386 3.10376L10.982 2.44876C11.528 1.84976 12.472 1.84976 13.018 2.44876L13.614 3.10376C13.976 3.50176 14.537 3.65176 15.05 3.48876L15.894 3.21976C16.667 2.97376 17.484 3.44576 17.657 4.23776L17.846 5.10276C17.961 5.62876 18.371 6.03876 18.897 6.15376Z'
      fill='#FFECC2'
    />
    <path
      d='M16.0879 13.6156C16.6086 14.1363 16.6086 14.9805 16.0879 15.5012C15.5672 16.0219 14.723 16.0219 14.2023 15.5012C13.6816 14.9805 13.6816 14.1363 14.2023 13.6156C14.723 13.0949 15.5672 13.0949 16.0879 13.6156'
      stroke='#FF6262'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3938 8.1166C10.9145 8.6373 10.9145 9.48152 10.3938 10.0022C9.87312 10.5229 9.0289 10.5229 8.5082 10.0022C7.9875 9.48152 7.9875 8.6373 8.5082 8.1166C9.0289 7.5959 9.87312 7.5959 10.3938 8.1166'
      stroke='#FF6262'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.3334 8.6665L8.66675 15.3332'
      stroke='#FF6262'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default DiscountIcon
