import clsx from "clsx"

const ChatIcon = ({ className }: { className?: string }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={clsx(className)}
  >
    <path
      opacity='0.62'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 8.89286V3.95679C18 2.32357 16.7316 1 15.1665 1H3.83353C2.2684 1 1 2.32357 1 3.95679V12.0876C1 13.7209 2.2684 15.0444 3.83353 15.0444H5.24971V18L7.982 16.0996'
      fill='#FF6262'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.5 20.3913H10.5C9.11875 20.3913 8 19.2239 8 17.7826V10.6087C8 9.16739 9.11875 8 10.5 8H20.5C21.8813 8 23 9.16739 23 10.6087V17.7826C23 19.2239 21.8813 20.3913 20.5 20.3913H19.25V23L15.5 20.3913Z'
      fill='#FF6262'
    />
  </svg>
)

export default ChatIcon
