import {
  BLUR_DATA_URL,
  IMAGE_PLACEHOLDER_URL,
  PROXY_IMG_API,
} from 'config/constants'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import { isAbsent, isSame, omit } from 'lib/utils'
import Image, { ImageProps } from 'next/image'
import { useMemo, useState } from 'react'

interface Props
  extends Omit<ImageProps, 'src' | 'placeholder' | 'blurDataURL'> {
  src: string | undefined
  proxy?: boolean
  fetching?: boolean
  skipLoading?: boolean
}

let Img = ({
  src,
  proxy = false,
  fetching = false,
  skipLoading = false,
  ...rest
}: Props) => {
  let [loading, setLoading] = useState(!skipLoading)

  useIsomorphicLayoutEffect(() => {
    !skipLoading && setLoading(true)
  }, [src, skipLoading])

  let imgSrc = useMemo(
    () =>
      fetching || isAbsent(src)
        ? BLUR_DATA_URL
        : isSame(src, IMAGE_PLACEHOLDER_URL)
        ? IMAGE_PLACEHOLDER_URL
        : proxy
        ? `${PROXY_IMG_API}${src}`
        : src,
    [fetching, src, proxy]
  )

  return (
    <>
      <Image
        src={imgSrc}
        placeholder='blur'
        blurDataURL={BLUR_DATA_URL}
        onLoad={() => setLoading(false)}
        {...rest}
      />

      {loading && (
        <Image
          src={BLUR_DATA_URL}
          placeholder='blur'
          blurDataURL={BLUR_DATA_URL}
          {...omit(['width', 'height'], rest)}
          layout='fill'
          objectFit='cover'
        />
      )}
    </>
  )
}

export default Img
