import { isNonEmptyArray, isPresent, isSame } from 'lib/utils'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCart } from 'store/api'
import { selectCity, selectLng } from 'store/auth'

let useCart = () => {
  let queryLng = useSelector(selectLng) ?? 'ru'

  let city = useSelector(selectCity)
  let {
    items,
    payment_type,
    address,
    original_sum,
    products_sum,
    sum,
    ...rest
  } = useSelector(selectCart(queryLng ?? 'ru'))

  let cartQty = useMemo(
    () => items.reduce((acc, { quantity }) => acc + quantity, 0),
    [items]
  )

  let total = sum
  let originalCartTotal = original_sum

  let cartTotal = useMemo(
    () =>
      isNonEmptyArray(rest.applied_coupons)
        ? items.reduce(
            (acc, { price, quantity }) =>
              acc + (price ?? 0) * quantity,
            0
          )
        : products_sum,
    [items, products_sum, rest.applied_coupons]
  )

  let deliveryPrice = isNonEmptyArray(rest.applied_coupons)
    ? original_sum - products_sum
    : rest.delivery?.deliveryPrice ?? 0

  let isDefaultAddressChosen = useMemo(
    () => isPresent(city) && isSame(address?.meta, 'default_address'),
    [address?.meta, city]
  )

  return {
    ...rest,
    total,
    deliveryPrice,
    items,
    cartQty,
    cartTotal,
    originalCartTotal,
    address,
    isDefaultAddressChosen,
    paymentType: payment_type,
  }
}

export default useCart
