import clsx from 'clsx'
import ExtLink from 'components/common/ExtLink'
import Img from 'components/common/Img'
import IntLink from 'components/common/IntLink'
import { useKeenSlider } from 'keen-slider/react'
import {
  isDiff,
  isNonEmptyArray,
  isNonEmptyString,
  isPresent,
  isSame,
} from 'lib/utils'
import { FC, useEffect, useRef, useState } from 'react'
import { IBanner } from 'store/api'

interface IPromoPanelBannerSliderProps {
  banners: IBanner[]
}

const PromoPanelBannerSlider: FC<IPromoPanelBannerSliderProps> = ({
  banners,
}) => {
  const images = banners.map((b) => b.img_src)
  const links = banners.map((b) => b.banner_link)

  const [currentSlide, setCurrentSlide] = useState(0)

  const timeout = useRef<NodeJS.Timeout | null>(null)
  let [ref] = useKeenSlider<HTMLDivElement>(
    {
      slides: {
        spacing: 14,
      },
      loop: true,
      defaultAnimation: {
        duration: 3000,
        easing: (t) => 1 - (1 - t) ** 40,
      },
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
      },
    },
    [
      (slider) => {
        let mouseOver = false
        function clearNextTimeout() {
          isPresent(timeout.current) && clearTimeout(timeout.current)
        }
        function nextTimeout() {
          isPresent(timeout.current) && clearTimeout(timeout.current)
          if (mouseOver) return
          timeout.current = setTimeout(() => {
            slider.next()
          }, 3000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      },
    ]
  )

  useEffect(() => {
    return () => {
      isPresent(timeout.current) && clearTimeout(timeout.current)
    }
  }, [])

  return (
    <div className='relative w-[250px] mx-auto'>
      <div ref={ref} className='keen-slider'>
        {images.map((path, index) => (
          <div
            key={path}
            className='keen-slider__slide relative overflow-hidden rounded-xl flex z-10 w-[250px] h-[300px]'
          >
            {isNonEmptyArray(links) &&
              (isNonEmptyString(links[index]) ? (
                links[index]?.startsWith('http') ? (
                  <ExtLink key={index} href={`${links[index]}`}>
                    <Img
                      unoptimized
                      skipLoading
                      src={path}
                      alt=''
                      layout='fill'
                      objectFit='contain'
                      priority
                    />
                  </ExtLink>
                ) : (
                  <IntLink key={index} href={`${links[index]}`}>
                    <Img
                      unoptimized
                      skipLoading
                      src={path}
                      alt=''
                      layout='fill'
                      objectFit='contain'
                      priority
                    />
                  </IntLink>
                )
              ) : (
                <Img
                  unoptimized
                  skipLoading
                  src={path}
                  alt=''
                  layout='fill'
                  objectFit='contain'
                  priority
                />
              ))}
          </div>
        ))}
      </div>

      <div className='w-full px-8 pt-6 pb-0 lg:py-8 flex gap-4 justify-between'>
        {images.map((path, idx) => (
          <div
            key={path}
            className={clsx(
              'rounded-lg h-1 w-full transition-colors duration-300 ease-linear',
              {
                'bg-accent': isSame(currentSlide, idx),
                'bg-lightYellow': isDiff(currentSlide, idx),
              }
            )}
          />
        ))}
      </div>

      <p className='px-6 text-sm text-greyA1 text-center leading-tight underline'>
        <IntLink href='/aktsii'>Все акции</IntLink>
      </p>
    </div>
  )
}

export default PromoPanelBannerSlider
