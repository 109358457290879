import { XIcon } from '@heroicons/react/solid'
import clsx from 'clsx'

type Props = {
  condition: boolean
  onClick: () => void
  className?: string
}

let ResetButton = ({ condition, onClick, className }: Props) => {
  return condition ? (
    <span
      aria-label='Очистить'
      className={clsx(
        'absolute z-20 focus:outline-none py-2 -my-2 pr-4 -mr-4 lg:p-0 lg:m-0 cursor-pointer',
        className
      )}
      onClick={onClick}
    >
      <XIcon
        className='w-5 h-5 text-bodyGrey hover:text-accent'
        aria-hidden='true'
      />
    </span>
  ) : null
}

export default ResetButton
