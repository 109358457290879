import clsx from 'clsx'
import {
  MouseEvent,
  ReactNode,
  useCallback,
  type AnchorHTMLAttributes,
} from 'react'

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string
  className?: string
  children: ReactNode
}

let ExtLink = ({ href, className, children, ...props }: Props) => {
  let handleClick = useCallback(
    (
      e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
      href: string
    ) => {
      e.preventDefault()
      window.open(href, '_blank')
    },
    []
  )

  return (
    <a
      href={href}
      className={clsx(className)}
      onClick={(e) => handleClick(e, href)}
      {...props}
    >
      {children}
    </a>
  )
}

export default ExtLink
