import clsx from 'clsx'

let TelegramIcon = ({ className }: { className?: string }) => (
  <svg
    className={clsx(className)}
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 0C2.68629 0 0 2.68629 0 6V34C0 37.3137 2.68629 40 6 40H34C37.3137 40 40 37.3137 40 34V6C40 2.68629 37.3137 0 34 0H6ZM13.5166 21.634L15.2852 27.6505C15.4398 28.1703 16.0714 28.339 16.4526 27.961L19.1643 25.234L23.7644 28.744C24.4984 29.3065 25.5504 28.8948 25.7399 27.97L28.9786 12.202C29.1354 11.4303 28.4014 10.7845 27.6892 11.068L8.41121 18.7495C7.84274 18.9745 7.87105 19.8138 8.45041 20.0005C8.45041 20.0005 13.5144 21.6318 13.5166 21.634Z'
      fill='currentColor'
    />
  </svg>
)

export default TelegramIcon
